.slideshow-container {
  max-width: 100%;
  width: 700px;
  height: auto;
}

.slideshow-container .img-wrapper {
  text-align: center;
  position: relative;
}

.slideshow-container .img-wrapper .img-overlay {
  position: absolute;
  height: 20%;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.img-overlay {
  top: 0;
}

.slideshow-container .img-wrapper img.slide-image {
  max-height: 82vh;
  max-width: 100%;
  width: 100%;
  margin-bottom: 30px;
}

.slideshow-container .slideshow-details {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  padding: 0 3px;
  bottom: 0;
  position: absolute;
  width: 98%;
}

.slide-image-holder img {
  z-index: 0;
  max-height: 82vh;
  max-width: 100%;
  width: 100%;
}

.margin-space {
  margin-bottom: 30px;
}

@media only screen and (max-width: 599px) {
  .slideshow-container .slideshow-details {
    font-size: 12px;
  }
}

.slideshow-details-section-left {
  flex: 0 0 30%;
}

.slideshow-controls {
  flex: 0 0 40%;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  bottom: 0;
}

.slideshow-controls svg {
  vertical-align: middle;
  font-size: 18px;
  width: 30px;
  height: 30px;
}

.slideshow-controls button {
  border: none;
  background: none;
  cursor: pointer;
}

.slideshow-controls button.play-button {
  font-size: 18px;
}

.slideshow-details-section-right {
  flex: 0 0 28%;
  text-align: right;
  padding-right: 3px;
}

.slide-detail svg {
  vertical-align: middle;
  margin-right: 3px;
  font-size: 16px;
}

.img-spinner {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-div {
  width: 100%;
  height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  position: absolute;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #fad201; /* Blue */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
}

.primary-distresses {
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
}

.table-cell {
  color: #b4b4b4;
  border: 2px solid #000;
  background: #dbdbdb;
  width: 33%;
}

.table-cell-selected {
  border: 2px solid #000;
  background: #ffc85c;
  width: 33%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.segment-info-bar {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
}

.segment-info-bar .segment-info-bar__section {
  margin-top: 10px;
}

.segment-info-bar .segment-info-bar__section svg {
  vertical-align: middle;
  font-size: 20px;
}

.segment-info-bar > .segment-info-bar__section:last-child {
  margin-bottom: 10px;
}

.segment-info-bar__street-name {
  display: flex;
  justify-content: center;
}

.segment-info-bar__street-name svg {
  margin-right: 5px;
}

.segment-info-bar__details {
  display: flex;
  justify-content: space-between;
}

.segment-info-bar__details__avg-rating__dot {
  width: 13px;
  height: 13px;
  border-radius: 7px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-right: 5px;
  vertical-align: bottom;
}

@media only screen and (max-width: 599px) {
  .segment-info-bar__details__avg-rating__value {
    display: block;
    margin-top: 3px;
  }

  .segment-info-bar__details__length__value {
    display: block;
  }
}

.analysis-container {
  display: flex;
  padding: 5px 0 8px;
  text-align: center;
  position: absolute;
  z-index: 200000;
  margin-left: 5px;
}

.analysis-item {
  padding: 10px 10px 5px 10px;
  margin: 5px 5px 0 5px;
  background-color: white;
  border: 1px white solid;
  border-radius: 10px;
  /* flex: 1.25; */
  font-family: "Open Sans", sans-serif;
  color: white;
}

.length {
  background-color: #343332;
}

.analysis-number {
  font-size: 2em;
  font-weight: bolder;
}

.analysis-text {
  font-size: 1em;
}

.pothole {
  background-color: #d7191c;
}

.rb-popup-overlay {
  position: absolute;
  top: 0;
  left: 10vw;
  right: 10vw;
  bottom: auto;
  margin: auto;
  background: none;
  z-index: 200001;
}

.rb-popup {
  width: 100%;
  border-radius: 4px;
  padding: 5px 15px;
  background: rgba(255, 255, 255, 0.75);
}

.rb-popup-close-button {
  top: 2px;
  right: 6px;
  font-size: 20px;
  padding: 5px;
}

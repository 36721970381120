.center-icon-button {
  position: absolute;
  z-index: 10;
  background-color: rgba(250, 250, 250);
  left: 11px;
  width: 26px;
  border-radius: 2px;
  height: 26px;
  cursor: pointer;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-icon {
  width: 14px;
  height: 14px;
}

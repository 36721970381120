.modal-overlay {
  padding: 0;
}

.modal {
  background: #fff;
  padding: 4px;
  max-width: 90%;
  border-radius: 4px;
}

.modal-close-button {
  top: 1px;
  right: 2px;
  font-size: 20px;
  padding: 5px;
  background: #fff;
  border-bottom-left-radius: 4px;
  z-index: 1;
}

.point-rating-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.point-rating-container hr {
  flex: 0 0 43%;
  margin: auto 1%;
  border: 0;
  height: 18px;
  transition: background-color 0.3s ease 0s;
}

.point-rating-container img {
  width: 10%;
  flex: 0 1% 10%;
  margin-top: 3px;
}

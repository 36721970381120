.tooltip {
  position: absolute;
  cursor: pointer;
  bottom: 25px;
  right: 15px;
  border-radius: 50px;
  padding: 1px;
  background-color: #fad201;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 50px;
  background-color: lightgray;
  color: #000;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  bottom: 65px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.question-mark {
  width: 55px;
}

.help-modal {
  position: absolute;
  width: 100vw;
  height: 99vh;
  background-color: rgba(0, 0, 0, 0);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.help-modal-content {
  margin: auto;
  position: absolute;
  background-color: white;
  padding: 10px;
  width: 400px;
  border-radius: 0.5rem;
  margin: auto;
  max-height: 75vh;
  overflow-y: scroll;
}
.help-close-button {
  width: 10%;
  float: right;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: white;
}
.help-close-button:hover {
  background-color: darkgray;
}

.help-modal-image {
  width: 100%;
}

.logo {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  width: 130px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  border: 1px solid #000;
}
